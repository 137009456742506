import React, { useEffect, useState } from 'react'
import './App.scss'
import Header from '../header/Header'
import { useMenu, usePages, useServices, useCustomSettings } from '../../utils/fetchUtils'
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router'
import DefaultPage from '../../pages/defaultPage/DefaultPage'
import Home from '../../pages/home/Home'
import { BounceLoader } from 'react-spinners'
import { getPageForSlug } from '../../utils/routes';
import { PageHome, IDefaultPage } from '../../@types/types';
import Services from '../../pages/services/Services';
import passwordHash from 'password-hash'
import Footer from '../footer/Footer';
import Media from '../../pages/media/Media';
import MediaDetail from '../../pages/mediaDetail/MediaDetail';
import { Helmet } from 'react-helmet';

const App: React.SFC<RouteComponentProps> = props => {
  const menuItems = useMenu()
  const pages = usePages()
  const services = useServices()
  const customSettings = useCustomSettings()
  const passwordInUrlIsValid = userIsAuthenticated(props.location.search, props.location.pathname)

  if(!passwordInUrlIsValid && customSettings && customSettings.PASSWORD_PROTECT) {
    return <div>Not authenticated</div>
  }

  if (!menuItems.length || !pages.length) {
    return (
      <div className="loader center" style={{}}>
        <BounceLoader size={60} color={'#5cc4be'}/>
      </div>
    )
  }
  const { pathname, search } = props.location
  const homeContent = getPageForSlug(pages, 'home')  as PageHome
  
  return (
    <div className="App">
      {homeContent && getParagraphFromHtml(homeContent.content.rendered) ?
        <Helmet>
          <meta name="description" content={getParagraphFromHtml(homeContent.content.rendered) || ''} />
        </Helmet>
      : null }

      <Header menuItems={menuItems} goToHomePage={goToHomePage} pathname={pathname}/>

      <Switch>
        <Route exact path="/media/:messageId" render={() => <MediaDetail content={getPageForSlug(pages, 'media') as IDefaultPage}/>} />
        <Route exact path="/media" render={() => <Media content={getPageForSlug(pages, 'media') as IDefaultPage}/>} />
        <Route exact path="/services" render={() => <Services services={services} content={getPageForSlug(pages, 'services') as IDefaultPage}/>} />
        <Route exact path="/" render={(props) => <Home {...props} services={services} content={homeContent} />} />
        <Route render={props => <DefaultPage {...props} pages={pages as IDefaultPage[]} />} />
      </Switch>

      <Footer />
    </div>
  )

  function goToHomePage() {
    props.history.push({
      pathname: '/',
      search
    })
  }
}

export default withRouter(React.memo(App))

function userIsAuthenticated(passwordFilledIn: string, currentUrl: string) {
  const [validPassword, setValidPassword] = useState(true)

  useEffect(() => {
    const hashedPassword = process.env.REACT_APP_HASHED_PASSWORD
    const queryPassword = passwordFilledIn.split('=')[1]
    if(currentUrl.includes('/wp/')) {
      setValidPassword(true)
      return
    }
    if(hashedPassword) {
      setValidPassword(passwordHash.verify(queryPassword, hashedPassword))
    }
  }, [])

  return validPassword
}

function getParagraphFromHtml(html: string): string | null {
  const regexParagraphSearch = /<\s*p[^>]*>(.*?)<\s*\/\s*p>/g
  const paragraph = regexParagraphSearch.exec(html)
  
  return paragraph ? paragraph[1] : null
}