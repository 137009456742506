import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import parse from 'html-react-parser'
import { getPageForSlug } from '../../utils/routes'
import { IDefaultPage, Page, PageContact, AcfLocation } from '../../@types/types'
import LeafletMap from '../../components/leafletMap/LeafletMap';
import HelmetHead from '../../components/helmetHead/HelmetHead';

interface IProps {
  pages: IDefaultPage[]
}

const DefaultPage: React.SFC<IProps & RouteComponentProps> = props => {
  const { pathname } = props.location
  const pages = props.pages
  const pageDetails = getPageForSlug(pages, pathname) as IDefaultPage

  if(!pageDetails) {
    // No page found
    return null
  }

  // const locationDetails = null
  const locationDetails = getContactPageLocationDetails(pageDetails)
  const subtitle = pageDetails.acf ? pageDetails.acf.subtitle : null
  const pageTitle = parse(pageDetails.title.rendered)

  return (
    <div className={`DefaultPage page-wrapper ${subtitle ? 'has-subtitle' : ''}`}>
      <HelmetHead pageTitle={pageTitle.toString()} />

      <h1>{pageTitle}</h1>
      { subtitle ? <h2>{subtitle}</h2> : null }
      <div className="page-content">{parse(pageDetails.content.rendered)}</div>

      { locationDetails && <LeafletMap locationDetails={locationDetails}/> }
    </div>
  )

  function getContactPageLocationDetails(pageDetails: Page): AcfLocation | null {
    const isContactPage = pageDetails.slug === 'contact'
    if(!isContactPage) {
      return null
    }
    
    const contactPage = pageDetails as PageContact
    return contactPage.acf.map
  }
}

export default withRouter(React.memo(DefaultPage))
