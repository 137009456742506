import { Page, Menu, Service, Message, Comment, Settings } from "../@types/types";
import { BASE_URL } from "../utils/constants";

interface IFetchOptions {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
  headers?: any
  body?: string | FormData
  redirect?: "follow" | "error" | "manual"
  credentials?: "include" | "omit" | "same-origin"
  mode?: "same-origin" | "cors" | "navigate" | "no-cors",
}

interface IRestError {
  timestamp: string
  status: number
  error: string
  message: string
  path: string
}

const BASIC_URL = `${BASE_URL}/wp-json/wp/v2`
const MENUS_URL = `${BASE_URL}/wp-json/menus/v1`
const headers = {
  'Authorization': 'Basic ' + Buffer.from('nicoline@interact-clinpharm.com:7713_Jan19').toString('base64'),
  'Content-Type': 'application/json; charset=utf-8'
}

export default class InteractWordpressService {
  static getAllPages(): Promise<Page[]> {
    const options: IFetchOptions = {
      // headers,
      method: 'GET'
    }

    return this.fetchApiCall(`${BASIC_URL}/pages`, options)
  }
  
  static getMenuItems(): Promise<Menu> {
    const options: IFetchOptions = {
      // headers,
      method: 'GET'
    }

    return this.fetchApiCall(`${MENUS_URL}/menus/menu-1`, options)
  }
  
  static getServices(): Promise<Service[]> {
    const options: IFetchOptions = {
      // headers,
      method: 'GET'
    }

    return this.fetchApiCall(`${BASIC_URL}/interact_services`, options)
  }
  
  
  static getCustomSettings(): Promise<Settings> {
    const options: IFetchOptions = {
      // headers,
      method: 'GET'
    }

    return this.fetchApiCall(`${BASE_URL}/wp-json/custom-settings/v1/password`, options)
  }
  
  static getMessages(page: number): Promise<{ data: Message[], totalPages: number }> {
    const options: IFetchOptions = {
      // headers,
      method: 'GET'
    }

    return this.fetchMessagesApiCall(`${BASIC_URL}/posts?page=${page}&per_page=5`, options)
  }

  static getSingleMessage(messageId: string): Promise<Message> {
    const options: IFetchOptions = {
      // headers,
      method: 'GET'
    }

    return this.fetchApiCall(`${BASIC_URL}/posts/${messageId}`, options)
  }

  static getPostComments(messageId: string): Promise<Comment[]> {
    const options: IFetchOptions = {
      // headers,
      method: 'GET'
    }

    return this.fetchApiCall(`${BASIC_URL}/comments?post=${messageId}`, options)
  }

  static postNewComment(messageId: string, content: string, author?: string): Promise<Comment[]> {
    const options: IFetchOptions = {
      method: 'POST'
    }

    return this.fetchApiCall(`${BASIC_URL}/comments?post=${messageId}&author_name=${author}&content=${content}`, options)
  }

  static async fetchApiCall(url: string, options: IFetchOptions) {
    try {
      return await fetch(url, options).then(this.handleRestResponse)
    } catch (error) {
      // sendExceptionToSentry(refreshError, error, options)
      throw error
    }
  }

  static async fetchMessagesApiCall(url: string, options: IFetchOptions) {
    try {
      return await fetch(url, options).then(this.handleMessagesRestResponse)
    } catch (error) {
      // sendExceptionToSentry(refreshError, error, options)
      throw error
    }
  }

  static handleRestResponse(response: any): Promise<any> {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response.json())
    } else {
      return Promise.resolve(response.json())
        .catch(err => ({ status: response.status, message: err.message }))
        .then((restError: IRestError) => {
          throw { status: response.status, message: restError.message }
        })
    }
  }

  static async handleMessagesRestResponse(response: any): Promise<any> {
    if (response.status >= 200 && response.status < 300) {

      let totalPages = 1
      for (var pair of response.headers.entries()) {
        if(pair[0] === 'x-wp-totalpages') {
          totalPages = Number(pair[1])
        }
      }
      const data = await Promise.resolve(response.json())
      return Promise.resolve({data, totalPages})
    } else {
      return Promise.resolve(response.json())
        .catch(err => ({ status: response.status, message: err.message }))
        .then((restError: IRestError) => {
          throw { status: response.status, message: restError.message }
        })
    }
  }
}