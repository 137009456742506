import React, { useEffect } from 'react'
import { IDefaultPage } from '../../@types/types'
import { withRouter, RouteComponentProps } from 'react-router'
import { BounceLoader } from 'react-spinners'
import { useSingleMessage } from '../../utils/fetchUtils'
import parse from 'html-react-parser'
import { scroller } from 'react-scroll'
import SingleComment from './singleComment/SingleComment'
import NewCommentForm from './newCommentForm/NewCommentForm'
import './MediaDetail.scss'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import HelmetHead from '../../components/helmetHead/HelmetHead';

interface IProps {
  content?: IDefaultPage
}

interface IRouteProps {
  messageId: string
}

const MediaDetail: React.SFC<IProps & RouteComponentProps<IRouteProps>> = props => {
  const pageDetails = props.content
  if (!pageDetails) {
    return null
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const subtitle = pageDetails.acf ? pageDetails.acf.subtitle : null
  const messageId = props.match.params.messageId
  const { loading, message, comments } = useSingleMessage(messageId)
  const pageTitle = parse(pageDetails.title.rendered)

  return (
    <div className={`MediaDetail page-wrapper ${subtitle ? 'has-subtitle' : ''}`}>
      <HelmetHead pageTitle={pageTitle.toString()} />
      
      <h1>{pageTitle}</h1>
      {subtitle ? <h2>{subtitle}</h2> : null}

      <div className="page-content space-after">
        <Link to={{ pathname: '/media', search: props.location.search }} className="back-link">
          <i className="fa fa-arrow-left" />
          BACK
        </Link>
        {!message ? (
          loading ? (
            <div className="center">
              <BounceLoader size={40} color="#5cc4be" />
            </div>
          ) : (
            <h3>Message not found</h3>
          )
        ) : (
          <div>
            {comments.length !== 0 ? (
              <div onClick={scrollToComments} className="comments-link">
                <i className="fa fa-comment" />
                <span>{comments.length}</span>
              </div>
            ) : null}
            <h2>{parse(message.title.rendered)}</h2>
            <div className="media-date">{format(message.date, 'DD MMMM YYYY')}</div>
            <div>{parse(message.content.rendered)}</div>
          </div>
        )}
      </div>

      <h2>
        <i className="fa fa-comment" /> Responses ({comments.length})
      </h2>
      <div id="comments">
        {comments.map(comment => (
          <SingleComment key={comment.id} comment={comment} />
        ))}
      </div>

      <NewCommentForm messageId={messageId} />
    </div>
  )

  function scrollToComments() {
    scroller.scrollTo('comments', {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -20
    })
  }
}

export default React.memo(withRouter(MediaDetail))
