import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import './style/defaultStyle.scss'
import './style/wordpressStyle.scss'
import App from './components/app/App'
import * as serviceWorker from './serviceWorker.js'
import { BrowserRouter } from 'react-router-dom'
require('dotenv').config()
import { hydrate, render } from 'react-dom'

const rootElement = document.getElementById('root')
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    rootElement
  )
} else {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    rootElement
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
