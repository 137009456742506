import React, { useState, useEffect } from 'react';
import { Page, MenuItem, Service, Message, Comment, Settings } from '../@types/types';
import InteractWordpressService from '../services/InteractWordpressService';

export function useMenu(): MenuItem[] {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])

  useEffect(() => {
    fetchMenuItems()
  }, [])

  async function fetchMenuItems() {
    try {
      const fetchedMenuItems = await InteractWordpressService.getMenuItems()
      if(fetchedMenuItems) {
        setMenuItems(fetchedMenuItems.items)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return menuItems
}

export function usePages(): Page[] {
  const [pages, setPages] = useState<Page[]>([])

  useEffect(() => {
    fetchPages()
  }, [])

  async function fetchPages() {
    try {
      const pages = await InteractWordpressService.getAllPages()
      if(pages) {
        setPages(pages)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return pages
}

export function useCustomSettings(): Settings | undefined {
  const [settings, setSettings] = useState<Settings>()

  useEffect(() => {
    fetchCustomSettings()
  }, [])

  async function fetchCustomSettings() {
    try {
      const fetchedSettings = await InteractWordpressService.getCustomSettings()
      if(fetchedSettings) {
        setSettings(fetchedSettings)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return settings
}

export function useServices(): Service[] {
  const [services, setServices] = useState<Service[]>([])

  useEffect(() => {
    fetchServices()
  }, [])

  async function fetchServices() {
    try {
      const fetchedServices = await InteractWordpressService.getServices()
      if(fetchedServices) {
        setServices(fetchedServices)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return services
}

export function useSingleMessage(messageId: string): { message: Message | null, loading: boolean, comments: Comment[] } {
  const [loading, setLoading] = useState<boolean>(true)
  const [message, setMessage] = useState<Message | null>(null)
  const [comments, setComments] = useState<Comment[]>([])

  useEffect(() => {
    fetchMessage()
  }, [])

  async function fetchMessage() {
    setLoading(true)
    try {
      const fetchedMessage = await InteractWordpressService.getSingleMessage(messageId)
      const fetchedComments = await InteractWordpressService.getPostComments(messageId)
      if(fetchedMessage) {
        setMessage(fetchedMessage)
      }
      if(fetchedComments) {
        setComments(fetchedComments)
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return { message, loading, comments }
}

export async function fetchMessages(page: number): Promise<{messages: Message[], hasMore: boolean}> {
  try {
    const { data, totalPages } = await InteractWordpressService.getMessages(page)
    const hasMore = (page < totalPages)
    return { messages: data, hasMore }
  } catch (err) {
    throw err
  }
}