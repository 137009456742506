import React from 'react'
import {Helmet} from "react-helmet";

interface IProps {
  pageTitle?: string
}

const HelmetHead: React.SFC<IProps> = props => {
  const pageTitle = props.pageTitle ? ' | ' + props.pageTitle : ''

  return (
    <Helmet>
      <title>interact!{pageTitle}</title>
    </Helmet>
  )
}

export default React.memo(HelmetHead)