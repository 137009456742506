import React from 'react'
import { PageHome, Service } from '../../@types/types'
import parse from 'html-react-parser'
import './Home.scss'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

interface IProps {
  content?: PageHome
  services: Service[]
}

const Home: React.SFC<RouteComponentProps<any> & IProps> = props => {
  const { content } = props
  const services = props.services

  if (!content) {
    return null
  }

  const { head_image, title_services_homepage } = content.acf

  return (
    <div className="Home">
      <div className="home-header">
        <div>
          <img className="home-header-image" src={head_image} title={'interact-header'} />
        </div>
        <div className="page-content">
          <div className="text-width-wrapper">
            <h1>{parse(content.title.rendered)}</h1>
            {parse(content.content.rendered)}
          </div>
        </div>
      </div>

      <div className="home-services page-wrapper">
        {title_services_homepage && <h2>{title_services_homepage}</h2>}
        <div className="service-blocks">
          {services.map(service => (
            <Link
              to={{ pathname: `/services`, search: props.location.search, hash: service.slug }}
              key={service.slug}
              className="home-service-block"
            >
              <img src={service.acf.icon.url} title={service.acf.title} />
              <h3>{service.acf.title}</h3>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default React.memo(withRouter(Home))
