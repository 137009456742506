import React, { useState } from 'react'
import useForm from '../../../utils/useForm';
import InputWrapper from '../../../components/elements/inputWrapper/InputWrapper';
import InteractWordpressService from '../../../services/InteractWordpressService';
import { scroller } from 'react-scroll';

interface IProps {
  messageId: string
}

interface NewComment {
  author_name: string
  content: string
}

const NewCommentForm: React.SFC<IProps> = (props) => {
  const [ notification, setNotification ] = useState<null | string>(null)
  const { handleSubmit, values, errors, onChange } = useForm<NewComment | undefined | null>({
    validate,
    onValidSubmit: onSubmit
  })
  
  return (
    <div className="NewCommentForm page-content">
      <br/>
      <form onSubmit={handleSubmit}>
        <h3>Write a comment</h3>
        {notification ? 
          <div className="notification">{notification}</div>
        : null}
        <InputWrapper label="Name">
          <input type="text" value={values ? values.author_name: ''} onChange={onChange} name="author_name"/>
        </InputWrapper>
        <InputWrapper label="Comment" error={errors ? errors.content : null}>
          <textarea onChange={onChange} value={values ? values.content: ''} name="content"/>
        </InputWrapper>
        <button type="submit">Send</button>
      </form>
    </div>
  )

  async function onSubmit(values: NewComment | undefined | null) {
    if(!values) {
      return
    }

    try {
      await InteractWordpressService.postNewComment(props.messageId, values.content, values.author_name)
      setNotification('Your comment is sent to interact! The message will be visible after it has been approved.')
    } catch(err) {
      console.error(err)
      setNotification('Something went wrong, please try again later.')
    }
    scroller.scrollTo('comments', {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: 0
    })
  }
}

export default React.memo(NewCommentForm)

function validate(values: NewComment | undefined | null) {
  const errors: any = {}

  if(!values || !values.content) { 
    errors.content = 'Please fill in a comment'
  }

  return errors
}