import React from 'react'
import './Footer.scss'

interface IProps {}

const Footer: React.SFC<IProps> = props => {
  const phone = '+31 (0)6 44 24 69 01'
  const email = 'nicoline@interact-clinpharm.com'
  const linkedin = 'https://nl.linkedin.com/in/nicoline-treijtel-067a7441'

  return (
    <>
      <footer className="Footer">
        <div className="social-icons">
          <a
            className="whatsapp"
            target="_blank"
            href={`https://wa.me/${filterPhoneNumber(phone)}`}
            title="whatsapp"
          >
            <i className="fab fa-whatsapp" />
          </a>
          <a className="linkedin" target="_blank" href={linkedin} title="linkedin">
            <i className="fab fa-linkedin-in" />
          </a>
          <a className="mail" target="_blank" href={`mailto:${email}`} title="mail">
            <i className="fas fa-envelope" />
          </a>
          <a
            className="phone"
            target="_blank"
            href={`tel:${filterPhoneNumber(phone)}`}
            title="phone"
          >
            <i className="fas fa-phone" />
          </a>
        </div>
        <div className="phone-and-email">
          <div className="phone">{phone}</div>
          <div className="email">{email}</div>
        </div>
      </footer>
      <div className="disclaimer">
        <div>
          webdesign by{' '}
          <a href="https://stam.codes" target="_blank">
            stam.codes
          </a>
        </div>
        <div>
          logo design by{' '}
          <a href="http://eidastudio.com" target="_blank">
            eidastudio.com
          </a>
        </div>
      </div>
    </>
  )
}

export default React.memo(Footer)

function filterPhoneNumber(value: string): string {
  return value.replace(/\s/g, '').replace(/ *\([^)]*\) */g, '')
}
