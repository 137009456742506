import React from 'react'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import './InputWrapper.scss'

interface IProps {
  label?: string
  fieldId?: string
  error?: string | null
}

const InputWrapper: React.FunctionComponent<IProps> = ({ children, label, fieldId, error }) => (
  <Tooltip
    overlay={<div className="error-tooltip">{error}</div>}
    trigger={error ? ['hover'] : []}
    placement={'topLeft'}
  >
    <div className={`input-wrapper ${error ? 'error-active' : ''}`}>
      {label ? (
        <label htmlFor={fieldId} className="form-label">
          {label}
        </label>
      ) : null}

      {children}
    </div>
  </Tooltip>
)

export default InputWrapper
