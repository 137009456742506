import React from 'react'
import { Comment } from '../../../@types/types';
import parse from 'html-react-parser'
import { format } from 'date-fns'

interface IProps {
  comment: Comment
}

const SingleComment: React.SFC<IProps> = ({comment}) => {
  console.log(comment)
  const author = !comment.author_name || comment.author_name === 'undefined' ? 'Anonymous' : comment.author_name
  return (
    <div className="SingleComment page-content">
      <div>{parse(comment.content.rendered)}</div>
      <div className="comment-info">Written by:   {author}   |   {format(comment.date, 'DD MMMM YYYY')}</div>
    </div>
  )
}

export default React.memo(SingleComment)