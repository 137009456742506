import React, { useEffect, useState, cloneElement } from 'react'
import { MenuItem } from '../../../@types/types'
import { slide as Menu } from 'react-burger-menu'

interface IProps {
  menuItems: MenuItem[]
  children: (menuContainerProps: MenuContainerProps) => React.ReactNode
}

export interface MenuContainerProps {
  closeMenu: () => void
}

const MenuContainer: React.SFC<IProps> = props => {
  const screenWidth = useWindowWidth()
  const isMobileMenu = useMobileMenu(screenWidth, props.menuItems.length)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const childParams = { closeMenu: () => setIsOpen(false) }

  if (isMobileMenu) {
    return (
      <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <Menu
          customBurgerIcon={<i className="fa fa-bars" />}
          customCrossIcon={<i className="fa fa-times" />}
          width={240}
          isOpen={isOpen}
          onStateChange={({ isOpen }) => setIsOpen(isOpen)}
          right
          disableAutoFocus
        >
          {props.children ? props.children(childParams) : null}
        </Menu>
      </div>
    )
  }

  return <div className="menu">{props.children(childParams)}</div>
}

export default MenuContainer

function useMobileMenu(screenWidth: number, menuItemsLength: number): boolean {
  const logoWidth = 320 // + extra width
  const sizePerMenuItem = 110
  const spaceLeft = screenWidth - logoWidth - menuItemsLength * sizePerMenuItem

  return spaceLeft <= 0
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return width
}
