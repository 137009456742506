import React from 'react'
import { AcfLocation } from '../../@types/types'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import './LeafletMap.scss'
import { Icon, IconOptions } from 'leaflet'

interface IProps {
  locationDetails: AcfLocation
}

const LeafletMap: React.SFC<IProps> = props => {
  const { locationDetails } = props
  const position = [Number(locationDetails.lat), Number(locationDetails.lng)] as [number, number]

  return (
    <div className="LeafletMap">
      <Map center={position} zoom={15}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        />

        <Marker position={position} icon={getCustomMarkerIcon()}>
          <Popup>
            <div>
              {locationDetails.address}
            </div>
          </Popup>
        </Marker>
      </Map>
    </div>
  )

  function getCustomMarkerIcon(): Icon<IconOptions> {
    const iconPerson = new L.Icon({
      iconUrl: require('../../assets/images/marker.svg'),
      iconRetinaUrl: require('../../assets/images/marker.svg'),
      shadowUrl: require('../../assets/images/marker-shadow.svg'),
      shadowRetinaUrl: require('../../assets/images/marker-shadow.svg'),
      shadowSize: [27, 58],
      shadowAnchor: [12.5, 58],
      iconSize: [25, 55],
      iconAnchor: [12.5, 55],
      className: 'leaflet-div-icon'
    })

    return iconPerson
  }
}

export default React.memo(LeafletMap)
