import { useState, useEffect } from 'react'
import { set } from 'lodash'

interface FormProps<T> {
  initialValues?: T | null
  validate: (values: T | undefined | null) => any
  onValidSubmit: (values: T) => void
  onInvalidSubmit?: (errors?: any) => void
}

interface FormReturn<T> {
  handleSubmit: (e: React.ChangeEvent<any>) => void
  values: T | null
  errors: any
  onChange: (e: React.ChangeEvent<any>) => void
  onChangeValue: (field: string, value: string | boolean | number) => void
  isDifferendFromInitial: boolean
}

function useForm<T extends any>({
  initialValues,
  validate,
  onValidSubmit,
  onInvalidSubmit
}: FormProps<T>): FormReturn<T> {
  const [values, setValues] = useState<T | null>(initialValues || null)
  const [errors, setErrors] = useState<T | null>(null)
  const [isDifferendFromInitial, setIsDifferentFromInitial] = useState(false)
  const [isPressedSubmit, setIsPressedSubmit] = useState(false)

  useEffect(() => {
    if (initialValues !== undefined) {
      setValues(initialValues)
    }
  }, [initialValues])

  async function onChange(e: React.ChangeEvent<any>) {
    const name = e.target.name
    const isCheckbox = e.target.type === 'checkbox'
    const value = isCheckbox ? e.target.checked : e.target.value

    // Set value
    onChangeValue(name, value)
  }

  function onChangeValue(field: string, value: string | boolean | number) {
    // Clone the values
    const clonedValues = Object.assign({}, values)

    // Set new value in the cloned values object
    const newValues = set(clonedValues, field, value)
    setValues(newValues)

    // Change isDifferendFromInitial status on first change
    if (!isDifferendFromInitial) {
      setIsDifferentFromInitial(true)
    }

    // Check validation again, if submit has already been pressed once
    if (isPressedSubmit) {
      passValidation(newValues)
    }
  }

  function handleSubmit(e: React.ChangeEvent<any>) {
    e.preventDefault()

    setIsPressedSubmit(true)

    const passedValidation = passValidation(values)

    if (!passedValidation) {
      return
    }

    // Passed the validation

    // Reset the form status
    setErrors(null)
    setIsDifferentFromInitial(false)

    // Send back to form
    if (values) {
      onValidSubmit(values)
      setIsPressedSubmit(false)
    }
  }

  function passValidation(values: T | null): boolean {
    // if (!values) {
    //   setErrors(null)
    //   if (onInvalidSubmit) {
    //     onInvalidSubmit()
    //   }
    //   return false
    // }

    const validatedValues = validate(values)

    if (validatedValues && Object.values(validatedValues).find(errorMessage => errorMessage !== null)) {
      // At least one error found
      setErrors(validatedValues)

      if (onInvalidSubmit) {
        onInvalidSubmit(errors)
      }
      return false
    }

    return true
  }

  return { handleSubmit, values, errors, onChange, onChangeValue, isDifferendFromInitial }
}

export default useForm
