import React, { useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import parse from 'html-react-parser'
import { IDefaultPage, Page, PageContact, AcfLocation, Message } from '../../@types/types'
import { fetchMessages } from '../../utils/fetchUtils'
import InfiniteScroll from 'react-infinite-scroller'

import './Media.scss'
import InteractWordpressService from '../../services/InteractWordpressService'
import { Link } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import HelmetHead from '../../components/helmetHead/HelmetHead';

interface IProps {
  content?: IDefaultPage
}

const Media: React.SFC<IProps & RouteComponentProps> = props => {
  const { pathname } = props.location
  const pageDetails = props.content
  const [messages, setMessages] = useState<Message[]>([])
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [fetchedPages, setFetchedPages] = useState<number[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
    loadMore(1)
  }, [])

  if (!pageDetails || !messages || !messages.length) {
    // No page found
    return (
      <div className="loader" key={0}>
        <BounceLoader size={30} color={'#5cc4be'} />
      </div>
    )
  }

  const subtitle = pageDetails.acf ? pageDetails.acf.subtitle : null
  const pageTitle = parse(pageDetails.title.rendered)

  return (
    <div className={`DefaultPage page-wrapper ${pathname} ${subtitle ? 'has-subtitle' : ''}`}>
      <HelmetHead pageTitle={pageTitle.toString()} />

      <h1>{pageTitle}</h1>
      {subtitle ? <h2>{subtitle}</h2> : null}
      {pageDetails.content.rendered ? (
        <div className="page-content">{parse(pageDetails.content.rendered)}</div>
      ) : null}

      <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={
          <div className="loader" key={0}>
            <BounceLoader size={30} color={'#5cc4be'} />
          </div>
        }
      >
        {messages.map((message, messageIndex) => {
          const content = message.content.rendered.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 100)

          const regexImgSrc = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g

          const imagesrc = regexImgSrc.exec(message.content.rendered)

          return (
            <div className="page-content media-message" key={message.id + messageIndex}>
              <div>
                <h3>{parse(message.title.rendered)}</h3>
                <div className="media-date">{message.date}</div>
                <div>{content}</div>
                <div>{content.length > 99 ? '...' : ''}</div>
                <Link className="link read-more" to={{ pathname: `/media/${message.id}`, search: props.location.search}}>
                  Read more
                </Link>
              </div>
              {imagesrc && imagesrc[0] ? (
                <img className="media-image" src={imagesrc[0]} title={'post_' + messageIndex} />
              ) : null}
            </div>
          )
        })}
      </InfiniteScroll>
    </div>
  )

  async function loadMore(page: number) {
    try {
      if ((!fetchedPages.includes(page - 1) && fetchedPages.includes(page)) || !hasMore) {
        return
      }

      const messagesRes = await InteractWordpressService.getMessages(page)

      if (messagesRes) {
        await setHasMore(page < messagesRes.totalPages)
        await setMessages(messages.concat(messagesRes.data))
      }
      await setFetchedPages(fetchedPages.concat([page]))
    } catch (err) {}
  }
}

export default withRouter(React.memo(Media))
