import React from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { MenuItem } from '../../@types/types'
import './Header.scss'
import { BASE_URL } from '../../utils/constants'
import interactLogo from '../../assets/images/logo-interact.png'
import MenuContainer, { MenuContainerProps } from './menuContainer/MenuContainer'
import { useSpring, animated } from 'react-spring'
import * as easings from 'd3-ease'

interface IProps {
  menuItems: MenuItem[]
  goToHomePage: () => void
  pathname: string
}

const Header: React.SFC<RouteComponentProps<any> & IProps> = props => {
  const pathname = props.pathname
  const logoAnimate = useSpring({ marginLeft: 0, from: { marginLeft: -300 }, config: { duration: 1200, easing: easings.easeCubic }})

  return (
    <header className="Header">
      <animated.div
        style={logoAnimate as any}
        className="interact-logo"
        onClick={props.goToHomePage}
      >
        <img src={interactLogo} alt="Logo interact" />
      </animated.div>
      <MenuContainer menuItems={props.menuItems}>
        {(menuProps: MenuContainerProps) =>
          props.menuItems.map(menuItem => {
            const activeMenuItem = isUrlCurrentPath(pathname, menuItem.title)

            return (
              <Link
                className={'menu-item ' + (activeMenuItem ? 'active' : '')}
                onClick={menuProps.closeMenu}
                key={menuItem.ID}
                to={{ pathname: removeBaseFromUrl(menuItem.url), search: props.location.search }}
              >
                {menuItem.title}
              </Link>
            )
          })
        }
      </MenuContainer>
    </header>
  )
}

export default React.memo(withRouter(Header))

function removeBaseFromUrl(url: string): string {
  const strippedBaseUrl = BASE_URL.replace('https://', '').replace('www.', '')
  const strippedUrl = url
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '')
    .replace('admin.interact-clinpharm.com', '')
  return strippedUrl.replace(strippedBaseUrl, '')
}

function isUrlCurrentPath(url: string, menuItem: string) {
  const urlFormatted = formatStrings(url)
  const menuItemFormatted = formatStrings(menuItem)

  // home page
  if ((urlFormatted === '' || urlFormatted === '/') && menuItemFormatted === 'home') {
    return true
  }

  return urlFormatted.includes(menuItemFormatted)
}

function formatStrings(value: string) {
  const lowerCased = value.toLowerCase() // to lower case
  const noSpaces = lowerCased.trim() // remove spaces
  return noSpaces.replace(/-|\s/g, '') // remove -
}
