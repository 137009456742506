import React, { useEffect } from 'react'
import './Services.scss'
import { useServices } from '../../utils/fetchUtils'
import parse from 'html-react-parser'
import { IDefaultPage, Service } from '../../@types/types'
import { withRouter, RouteComponentProps } from 'react-router'
import { Element, scroller } from 'react-scroll'
import HelmetHead from '../../components/helmetHead/HelmetHead';

interface IProps {
  content: IDefaultPage
  services: Service[]
}

const Services: React.SFC<IProps & RouteComponentProps<null>> = props => {
  const services = props.services
  const pageDetails = props.content
  const subtitle = pageDetails.acf ? pageDetails.acf.subtitle : null
  const hash = props.location.hash.replace('#', '')

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    scroller.scrollTo(hash, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -50
    })
  }, [hash])

  if (!services) {
    return null
  }

  const pageTitle = parse(pageDetails.title.rendered)
  
  return (
    <div className={`Services page-wrapper ${subtitle ? 'has-subtitle' : ''}`}>
      <HelmetHead pageTitle={pageTitle.toString()} />

      <h1>{pageTitle}</h1>
      { subtitle ? <h2>{subtitle}</h2> : null }
      <div className="services-wrapper">
        {services.map(service => (
          <Element name={service.slug} key={service.slug} className="service-block">
            <div className="image-wrapper ">
              <img src={service.acf.icon.url} title={service.acf.title} />
            </div>
            <div>
              <h3>{service.acf.title}</h3>
              <div className="service-description">{parse(service.acf.description)}</div>
            </div>
          </Element>
        ))}
      </div>
    </div>
  )
}

export default React.memo(withRouter(Services))
